import { section, container, grayColor, cardTitle, breadcrumbs, main, mainRaised, gridItem } from '../materialKitBase';

import { createStyles } from '@material-ui/core/styles';

export const categoryStyle = createStyles({
  main,
  mainRaised,
  breadcrumbs,
  gridItem,
  container: {
    ...container,
  },
  section: {
    ...section,
    padding: '70px 0px',
  },
  icon: {
    marginRight: '5px',
    width: 20,
    height: 20,
  },
  link: {
    display: 'flex',
  },
  cardTitle: {
    ...cardTitle,
    textAlign: 'center',
    marginBottom: '0px !important',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  cardHeaderImage: {
    position: 'relative',
    padding: '0',
    zIndex: 1,
    marginLeft: '15px',
    marginRight: '15px',
    marginTop: '-30px',
    borderRadius: '6px',
    '& img': {
      width: '100%',
      borderRadius: '6px',
      pointerEvents: 'none',
    },
    '& a': {
      display: 'block',
    },
  },
  textCenter: {
    textAlign: 'center',
  },
  categoryPage: {
    '& $mainRaised': {
      margin: '-40vh 0 0',
      padding: '20px',
      // margin: '2vh 0 0',
      // padding: '20px',
    },
    backgroundColor: grayColor[2],
  },
  parallax: {
    height: '70vh',
    overflow: 'hidden',
  },
  textMuted: {
    color: grayColor[22],
    fontWeight: 'normal',
  },
  textBySupplier: {
    color: grayColor[22],
    fontWeight: 'normal',
    fontSize: '0.725rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

    // float: 'right',
  },
});
