import React from 'react';
import { graphql, Link as GatsbyLink, PageRendererProps } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Link } from '@material-ui/core';
import classNames from 'classnames';
import { categoryStyle } from '../assets/jss/pages/categoryStyle';
import { DefaultHeader } from '../components/Header/Header';
import { Parallax } from '../components/Parallax/Parallax';
import { Breadcrumbs } from '../components/Breadcrumbs/Breadcrumbs';
import { Helmet } from 'react-helmet';
import { CategoryNode, ProductNode } from '../interfaces/contentful';
import { Card } from '../components/Card/Card';
import { CardHeader } from '../components/Card/CardHeader';
import { CardBody } from '../components/Card/CardBody';
import { Price } from '../components/Price/Price';
import { AddToCartButton } from '../components/Button/AddToCartButton';
import { useCookies } from 'react-cookie';
import { createCategoryTree } from '../helpers/treeHelper';
import { FormattedMessage } from 'react-intl';
import backgroundImage from '../assets/img/bg9.jpg';
import { GatsbyImage } from 'gatsby-plugin-image';

interface SupplierPageProps extends PageRendererProps {
  data: {
    supplier: {
      contentful_id: string;
      name: string;
      slug: string;
      products: ProductNode[];
    };
    categories: {
      edges: { node: CategoryNode }[];
    };
  };
}

export const supplierQuery = graphql`
  query($slug: String!, $locale: String!) {
    supplier: contentfulSuppliers(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      contentful_id
      name
      slug
      products: regionalproduct {
        contentful_id
        title
        slug
        price
        unit
        unitValue
        coverImage {
          title
          gatsbyImageData(layout: CONSTRAINED, aspectRatio: 1)
        }
        description {
          description
        }
        suppliedBy {
          name
          slug
          location {
            lat
            lon
          }
        }
        category {
          title
          slug
        }
        notAvailableReason
        invisible
      }
    }
    categories: allContentfulCategory {
      edges {
        node {
          slug
          title
          contentful_id
          parentCategory {
            slug
            contentful_id
          }
          regionalproduct {
            contentful_id
          }
        }
      }
    }
  }
`;

const useStyles = makeStyles(categoryStyle);

// TODO: here we display by appearing at, for each month one big container, inside, the tiles go in rows, similar like in main page for the latest, so 6 per row
const SuppliersPageTemplate: React.FC<SupplierPageProps> = ({ data }) => {
  const { supplier, categories } = data;

  const [cookies] = useCookies(['SeeInvisible']);
  if (!cookies['SeeInvisible'] && supplier.products) {
    supplier.products = supplier.products.filter((product) => product.invisible !== true);
  }

  const categoryTree = createCategoryTree(categories);

  const classes = useStyles();

  return (
    <div className={classes.categoryPage}>
      <Helmet>
        <title>{supplier.name}</title>
      </Helmet>
      <DefaultHeader categoryTree={categoryTree} />

      <Parallax image={backgroundImage} className={classes.parallax}>
        <div className={classes.container}>
          <Breadcrumbs>
            <Typography color="secondary">{supplier.name}</Typography>
          </Breadcrumbs>
        </div>
      </Parallax>

      <div className={classNames(classes.section)}>
        <div className={classes.container}>
          <div className={classNames(classes.main, classes.mainRaised)}>
            <Typography variant="h5" component="h2" gutterBottom>
              {supplier.name}
            </Typography>

            <Grid container justify="flex-start" spacing={1}>
              {supplier.products &&
                supplier.products.map((node) => {
                  return (
                    <Grid item key={node.contentful_id} md={2} sm={4} xs={6}>
                      <Card product>
                        <CardHeader image plain style={{ textAlign: 'center', padding: '10px 10px 0 10px' }}>
                          <GatsbyLink to={`/products/${node.slug}`}>
                            <GatsbyImage
                              alt={node.coverImage.title ? node.coverImage.title : ''}
                              image={node.coverImage.gatsbyImageData}
                            />
                          </GatsbyLink>
                          <span className={classes.textBySupplier}>{node.suppliedBy.name}</span>
                        </CardHeader>
                        <CardBody className={classes.textCenter} plain>
                          <h4 className={classes.cardTitle} style={{ minHeight: '40px' }}>
                            <GatsbyLink to={`/products/${node.slug}`}>{node.title}</GatsbyLink>
                            <br />
                            <span className={classes.textMuted}>
                              ({node.unit}: {node.unitValue})
                            </span>
                          </h4>
                          <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                            <FormattedMessage id="breadcrumbs.category" defaultMessage="Category" />:{' '}
                            <Link component={GatsbyLink} to={`/categories/${node.category.slug}`}>
                              {node.category.title}
                            </Link>
                          </div>
                          <Price product={node} />
                          <br />
                          <AddToCartButton product={node} quantity={1} size="sm" onlyIcon={true} />
                        </CardBody>
                      </Card>
                    </Grid>
                  );
                })}
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuppliersPageTemplate;
