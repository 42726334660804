import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { cardBodyStyle } from './CardBodyStyle';

interface CardBodyProps {
  className?: string;
  plain?: boolean;
  background?: boolean;
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose';
  style?: any;
}

const useStyles = makeStyles(cardBodyStyle);

export const CardBody: React.FC<CardBodyProps> = ({ className, plain, color, background, children, ...rest }) => {
  const classes = useStyles();
  const cardBodyClasses = classNames({
    [classes.cardBody]: true,
    [classes.cardBodyBackground]: background,
    [classes.cardBodyPlain]: plain,
    [classes.cardBodyColor]: color,
    [className!]: className !== undefined,
  });

  return (
    <div className={cardBodyClasses} {...rest}>
      {children}
    </div>
  );
};
