/* eslint-disable @typescript-eslint/ban-ts-ignore */

import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';

// core components
import { cardHeaderStyle } from './CardHeaderStyle';

interface CardHeaderProps {
  className?: string;
  plain?: boolean;
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose';
  noShadow?: boolean;
  image?: boolean;
  style?: object;
}

const useStyles = makeStyles(cardHeaderStyle);

export const CardHeader: React.FC<CardHeaderProps> = ({
  className,
  plain,
  noShadow,
  image,
  color,
  children,
  ...rest
}) => {
  const classes = useStyles();
  const cardHeaderClasses = classNames({
    [classes.cardHeader]: true,
    // @ts-ignore not possible to augment key yet in TS https://github.com/microsoft/TypeScript/issues/12754
    [classes[`${color}CardHeader`]]: color,
    [classes.cardHeaderPlain]: plain,
    [classes.cardHeaderImage]: image,
    [classes.noShadow]: noShadow,
    [className!]: className !== undefined,
  });

  return (
    <div className={cardHeaderClasses} {...rest}>
      {children}
    </div>
  );
};
