import React from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import { cardStyle } from './CardStyle';

interface CardProps {
  className?: string;
  plain?: boolean;
  profile?: boolean;
  background?: boolean;
  color?: 'primary' | 'info' | 'success' | 'warning' | 'danger' | 'rose';
  product?: boolean;
  blog?: boolean;
  style?: object;
}

const useStyles = makeStyles(cardStyle);

export const Card: React.FC<CardProps> = ({ className, blog, plain, profile, product, color, children, ...rest }) => {
  const classes = useStyles();
  const cardClasses = classNames({
    [classes.card]: true,
    [classes.cardPlain]: plain,
    [classes.cardBlog]: blog,
    [classes.cardProfile]: profile,
    [classes[color!]]: color, // TODO: this shows bigger problem with default props and TS https://medium.com/@martin_hotell/react-typescript-and-defaultprops-dilemma-ca7f81c661c7
    [classes.cardProduct]: product,
    [className!]: className !== undefined,
  });

  return (
    <div className={cardClasses} {...rest}>
      {children}
    </div>
  );
};
